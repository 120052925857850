import React from "react";
import {Container } from "react-bootstrap";
import Banner from "../Banner";
// import Img1 from "../../assets/img/our_serveices_1.png";
// import Img2 from "../../assets/img/our_serveices_2.png";

const Events = () => {
    return (
        <section id="Events">
            <Banner
                brdCurrent="Events"
                brdLink="Home"
                brdPath="/"
                // bannerTitle="Events"
                bannerDescription="Join the Celebration: Embrace life&#39;s precious moments and our steadfast community dedication. With Foundation Day, Birthdays, Holi, Diwali, we&#39;re not just celebrating; were taking active roles in road safety drives, tree planting, and life-saving blood donation camps. Be part of the meaningful difference we create!"
                btnTitle="Make A Appointment"
                textclass = 'text-white'
            />
            <Container>
                {/* <div className="row"> */}
                    <div className="section-heading">
                        <h3 className="section-title">About Our Event&apos;s:</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="350" height="2" viewBox="0 0 350 2" fill="none">
                            <path d="M1 1L349 1.00003" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div className="section-description mt-3">
                        <p className="section-paragraph d-flex align-items-end">Discover upcoming events where we celebrate milestones, promote road safety, organize blood donation drives, and contribute to a greener planet through tree planting initiatives. Be a part of our meaningful events!</p>
                    </div>
                {/* </div> */}
                <div className="row mt-4">
                    <div className="col-12 col-sm-6">
                        <div className="big-img">
                            <div className="img-title">Tokens of Excellence</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">Tokens of Excellence</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <p>
                                    &#34;Tokens of Excellence&#34; symbolize the recognition and accolades we have received for our outstanding achievements. These prestigious awards stand as a testament to our commitment to excellence, innovation, and unwavering dedication to delivering top-tier solutions and services. Each award represents a milestone in our journey towards continuous improvement and the highest standards of performance.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="small-img">
                        <div className="img-title">Discover Our Diverse Clientele</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">Discover Our Diverse Clientele</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <p>At SUIV, our reach spans across various industries and sectors, serving a wide range of esteemed clients. From established corporations to innovative startups, we have had the privilege of partnering with a multitude of businesses, each with unique needs and aspirations. Our client list is a testament to our versatility, adaptability, and commitment to delivering tailored solutions that drive success. Join our esteemed clientele and experience the difference of working with a partner who understands your industry and is dedicated to your growth.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="small-img">
                            <div className="img-title">Birthday Celebrations</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">Birthday Celebrations</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <p> &#34;Join us in commemorating the B-Day Celebrations of our esteemed employees and staff. At SUIV, we believe in celebrating the milestones and special moments that make our team unique and vibrant. These festivities not only foster a sense of camaraderie but also showcase our dedication to creating a positive and engaging work environment. We take pride in honouring the individuals who contribute to our success and make SUIV a great place to work.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="big-img">
                            <div className="img-title">Tree Plantations</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">Tree Plantations</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <p> &#34;Embrace our commitment to sustainability through our Tree Plantation Activity. At SUIV, we recognize the importance of preserving our environment for future generations. Our tree planting initiatives exemplify our dedication to ecological responsibility, as we nurture the earth by adding new life to it. Join us in making a positive impact as we contribute to greener landscapes, cleaner air, and a healthier planet.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="small-img">
                            <div className="img-title">Blood Donation Camp</div>
                            <div className="img-description">
                            <div className="desc-heading">
                                    <h3 className="desc-title">Blood Donation</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <p>
                                    &#39;Saving Lives Through Blood Donation Be a Hero - Donate Blood, Save Lives!&#39;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="small-img">
                            <div className="img-title">Blood Donation Camp</div>
                            <div className="img-description">
                            <div className="desc-heading">
                                    <h3 className="desc-title">Blood Donation</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <p>
                                    &#39;Saving Lives Through Blood Donation Be a Hero - Donate Blood, Save Lives!&#39;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <div className="big-img">
                            <div className="img-title">OFFICE GROUP</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">OFFICE GROUP</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    {/* <p>
                                    &#34;Tokens of Excellence&#34; symbolize the recognition and accolades we have received for our outstanding achievements. These prestigious awards stand as a testament to our commitment to excellence, innovation, and unwavering dedication to delivering top-tier solutions and services. Each award represents a milestone in our journey towards continuous improvement and the highest standards of performance.
                                    </p> */}
                                </div>
                            </div>
                        </div>
                        <div className="small-img">
                        <div className="img-title">ROAD SAFETY</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">ROAD SAFETY</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <p>Promoting Road Safety for a Safer Tomorrow Join Us in Our Mission to Ensure Safe Roads for All</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="small-img">
                            <div className="img-title">ROAD SAFETY</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">ROAD SAFETY</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <p>Promoting Road Safety for a Safer Tomorrow Join Us in Our Mission to Ensure Safe Roads for All</p>
                                </div>
                            </div>
                        </div>
                        <div className="big-img">
                            <div className="img-title">ROAD SAFETY</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">ROAD SAFETY</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <p>Promoting Road Safety for a Safer Tomorrow Join Us in Our Mission to Ensure Safe Roads for All</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="row mb-5">
                    <div className="col-12 col-sm-6">
                        <div className="big-img">
                            <div className="img-title">SAFETY TALK</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">SAFETY TALK</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <p>
                                    Empowering Safety Awareness Attend Our Regular Safety Talks for a Secure Workplace
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="small-img">
                        <div className="img-title">SAFETY TALK</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">SAFETY TALK</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <p>
                                    Empowering Safety Awareness Attend Our Regular Safety Talks for a Secure Workplace
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="small-img">
                            <div className="img-title">SAFETY TALK</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">SAFETY TALK</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <p>
                                    Empowering Safety Awareness Attend Our Regular Safety Talks for a Secure Workplace
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="big-img">
                            <div className="img-title">SAFETY TALK</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">SAFETY TALK</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <p>
                                    Empowering Safety Awareness Attend Our Regular Safety Talks for a Secure Workplace
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
            </Container>
        </section>
    );
};

export default Events;