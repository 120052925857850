import React from "react";
import { Container } from "react-bootstrap";
import industry1 from "../../assets/img/Expertise/Expertise1.jpeg";
import industry2 from "../../assets/img/Expertise/Expertise2.jpeg";
import industry3 from "../../assets/img/Expertise/Expertise3.jpeg";
import industry4 from "../../assets/img/Expertise/Expertise4.jpg";
// import industry5 from "../../assets/img/Expertise/Expertise5.jpg";

const IndustryExpertise = () => {
    return (
        <section id="IndustryExpertise">
            <Container>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <h3 className="section-title">Industries Expertise:</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="136" height="2" viewBox="0 0 136 2" fill="none">
                            <path d="M1 1H135" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <p className="col-12 col-sm-6 section-paragraph d-flex align-items-end">Unlocking Excellence in Energy Solutions<br/>We specialize in switchyards, electrical systems, control and instrumentation, MGR operations, CHP operations, and facility management services. With a commitment to excellence, we deliver top-notch solutions to power your success.</p>
                </div>
                <div className="industry-images my-5">
                    <div className="item openingSoonDiv d-flex flex-column justify-content-between position-relative">
                        <img className="img-fluid" src={industry1} alt="" />
                    </div>
                    <div className="item openingSoonDiv d-flex flex-column justify-content-between position-relative">
                        <img className="img-fluid" src={industry2} alt="" />
                    </div>
                    <div className="item openingSoonDiv d-flex flex-column justify-content-between position-relative">
                        <img className="img-fluid" src={industry3} alt="" />
                    </div>
                    <div className="item openingSoonDiv d-flex flex-column justify-content-between position-relative">
                        <img className="img-fluid" src={industry4} alt="" />
                    </div>
                </div>
                {/* <div className="my-4 text-center">
                    <button className="cta-btn" type="button">See More</button>
                </div> */}
            </Container>
        </section>
    );
};

export default IndustryExpertise;