import React from 'react';
import AboutImg from '../../assets/img/logo.jpeg';
import { Container } from 'react-bootstrap';

const Suivpl = () => {
    return (
        <section className='suivpl'>
            <Container>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <div className='section-heading'>
                            <h3 className='section-tittle'>SUIVPL</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" width="101" height="2" viewBox="0 0 101 2" fill="none">
                                <path d="M1 1H100" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        </div>
                        <div className='section-description'>
                            <p>SUIVPL is certified for ISO 9001:2015, Quality Management System underlining our commitment to delivering services that embodies aspects of quality, Safety and environment. We are working pan India across. We are equipped with specified machinery, tools & tackles, utility vehicles, and measuring / testing instruments to handle substantial volumes of work. To cater project specified works, we have on roll team of over 2000 experienced engineers, technicians and office staff, ensuring completion of works with required quality and timelines.Centrally located in Nagpur, our head office strategically facilitates pan India services with optimal turnaround times. In embracing the tenets of industrial excellence, we continuously aspire to foster growth, adopting technology, management principles, and commitment to client&apos;s satisfaction</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 img-box'>
                        <img className='suivpl-img img-fluid' src={AboutImg} alt='' />
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default Suivpl;