import React from "react";
import { Container } from "react-bootstrap";
import AboutImg from "../../assets/img/aboutUs.jpg";

const AboutUs = () => {
    return (
        <section id="AboutUs">
            <Container>
                <div className="background-box"></div>
                <div className="aboutUs-image">
                    <img src={ AboutImg } alt="about"/>
                </div>
                <div className="aboutUs-description">
                    <div className="description-heading">
                        <h3 className="section-title mb-0">About Us:</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="136" height="2" viewBox="0 0 136 2" fill="none">
                            <path d="M1 1H135" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <p>SUIVPL is incorporated in 2012 at Chandrapur, Maharashtra, India, stands as a testament to the visionary foresight of its founder, Mr.Harsh Mittal. We take pride in presenting ourselves as a premier entity headquartered in Nagpur, Maharashtra, engaged in AMCs/BMCs in O&M services encompassing Electrical, Control & Instrumentation, Mechanical Maintenance of Power Plant, e.g., BTG, AHP, CHP & MGR. We also are in erection & commissioning contracts for main plants, Coal Handling Plants (CHP) and Industrial/Commercial Facility Management System (FMS), along with housekeeping services. Our services are spanning across various private power generation plants, National Thermal Power Corporation (NTPC) projects, and State Electricity generation plants. Our existence is earmarked in SAIL (BSP) and HURL having proven records of customer satisfaction. Our roots firmly anchor us in principles of integrity, safety, quality, and value in all our business transactions.</p>
                </div>
            </Container>
        </section>
    );
};

export default AboutUs;