import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ContactUs from '../components/ContactUs/ContactUs';

const ContactUsPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData);

        if (!data.Name || !data.Email || !data.Subject || !data.Message) {
            alert('Please fill in all fields');
            return;
        }
        // Handle form submission here
        console.log(data);
        try {
            const response = await axios.post('http://64.227.188.115:9000/v1/api/sendmail',
                {
                    "name": data.Name,
                    "email": data.Email,
                    "subject": data.Subject,
                    "message": data.Message
                },
                {
                    headers: {
                        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiR0lMQkFSQ08ifQ.sCyfkr54nJyJFKWLZh4ndCVZPG2aWgoRLaqyuMIVM7s'
                    }
                }

            );
            console.log(response.data.message);
            if (response.data.status === 'success') {
                alert(response.data.message);
                resetForm();
            }
            console.log("2",response.data);
        } catch (error) {
            console.error('Error sending form data', error);
        }

    };
    const resetForm = () => {
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
    };

    return (
        <>
            <ContactUs 
                onSubmit={handleSubmit} 
                name={name} setName={setName}
                email={email} setEmail={setEmail}
                subject={subject} setSubject={setSubject}
                message={message} setMessage={setMessage} />
        </>
    );
};

export default ContactUsPage;