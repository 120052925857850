import React from "react";
import { Container } from "react-bootstrap";
import AboutImg from "../../assets/img/MD pic.jpeg";

const PageHead = () => {
    return (
        <section className="ManagementBoardSection">
            <Container>
                <div className="ManagementBoard-image">
                    <img src={ AboutImg } alt="md" style={{objectFit: 'contain'}}/>
                </div>
                <div className="ManagementBoard-description">
                    <div className="description-heading">
                        <h3 className="section-title mb-0">Our MD Messaage:</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="136" height="2" viewBox="0 0 136 2" fill="none">
                            <path d="M1 1H135" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <p>I&#39;m delighted to introduce our organization, a beacon of vision and dedication since 2012. Originating from CSTPS Chandrapur, Maharashtra, we&#39;ve grown into a force anchored in integrity, safety, quality, and value. From Nagpur, Maharashtra, we excel in Electrical, Control and instrumentation, and Mechanical Maintenance, alongside EPC Contracts for Coal Handling Plants and Facility Management Systems. Our reach extends to private power generation, NTPC collaborations, and State Electricity Boards, reflecting our pursuit of excellence and community commitment.</p>
                    <p>This preface embodies our dedication to corporate excellence, fostering growth, innovation, and unwavering commitment. My appreciation to the SUIV entire team; your dedication propels our growth. Let&#39;s stride forward, embracing challenges as opportunities, and maximizing each moment&#39;s potential. </p>   
                </div>
            </Container>
        </section>
    );
};

export default PageHead;