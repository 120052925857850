import React, { useContext } from "react";
import { Nav, Navbar } from "react-bootstrap";
import Logo from "../assets/img/logo.jpeg";
import whatsapp from '../assets/img/whatsapp-logo.png';
import ActiveLinkContext from "../Context/ActiveLinkContextBase";
import { Link } from "react-router-dom";
// import Profile from "../components/Header/Profile";
// import { Link } from "react-router-dom";

const Header = () => {
    const { activeLink } = useContext(ActiveLinkContext);
    return (
        <>
        <Navbar expand="lg">
            <div className="container">
                <Navbar.Brand href="#home" className="d-flex align-items-center fw-bold fs-4">
                    <img
                        src={Logo}
                        height="60"
                        className="d-inline-block align-top me-4"
                        alt="React Bootstrap logo"
                    />
                    SAI URJA INDO VENTURES PVT.LTD.
                </Navbar.Brand>
                <div className="navbar-right-Icons">
                    {/* <Profile cls="d-lg-none" /> */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </div>
                <Navbar.Collapse className="d-lg-flex justify-content-around" id="basic-navbar-nav">
                    <Nav className="m-auto">
                        <Link to="/" className={activeLink === "/" ? "active nav-link" : "nav-link"}>Home</Link>
                        <Link to="AboutUs" className={activeLink === "/AboutUs" ? "active nav-link" : "nav-link"} >About Us</Link>
                        <Link to="Services" className={activeLink === "/Services" ? "active nav-link" : "nav-link"}>Services</Link>
                        <Link to="Events" className={activeLink === "/Events" ? "active nav-link" : "nav-link"} >Events</Link>
                        <Link to="ContactUs" className={activeLink === "/ContactUs" ? "active nav-link" : "nav-link"}>Contact Us</Link>
                        {/* <Link to="Career" className={activeLink === "/Career" ? "active nav-link" : "nav-link"} >Career</Link> */}
                    </Nav>
                    {/* <Profile cls="d-none d-lg-block" /> */}
                </Navbar.Collapse>
            </div>
        </Navbar>
        {/* <div className="header-link-container">
            <Container className="d-flex justify-content-center align-items-center">
                <a className="header-links" href="tel:0000000000" target="_blank" rel="noreferrer">+91-000-00000</a>
                <a className="header-links" href="mailto:info@suiv.com" target="_blank" rel="noreferrer">info@suiv.com</a>
                <a className="header-links" href="www.suiv.com" target="_blank" rel="noreferrer">www.suiv.com</a>
            </Container>
        </div> */}
        <a target="_blank" rel="noreferrer" className="position-relative" href='https://api.whatsapp.com/send/?phone=919970778169&text=Hi'><img className="pulse" src={whatsapp} alt="" /></a>
        </>
    );
};

export default Header;