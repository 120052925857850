/* eslint-disable react/prop-types */
import React from 'react';
import { Breadcrumb, BreadcrumbItem, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const Banner = (props) => {
    const navigate = useNavigate();
    const { brdCurrent, brdLink, brdPath, bannerTitle, bannerDescription, btnTitle, textclass } = props;

    const FunRedirect = () => {
        navigate("/ContactUs");
    };

    return (
        <div className="banner mb-5">
            <Container>
                <Breadcrumb> 
                    <BreadcrumbItem><Link className={textclass ? textclass : ""} to={brdPath}>{brdLink}</Link></BreadcrumbItem> 
                    <BreadcrumbItem className={textclass ? textclass : ""} active>{brdCurrent}</BreadcrumbItem> 
                </Breadcrumb>
                <div className="banner-details w-100 h-100">
                    <div className="banner-heading">
                        <h2 className={textclass ? `banner-tittle ${textclass}` : "banner-tittle"} >{bannerTitle}</h2>
                        <svg xmlns="http://www.w3.org/2000/svg" width="130" height="2" viewBox="0 0 130 2" fill="none">
                            <path d="M1 1H129" stroke="#3F3F3F" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div className={textclass ? `banner-description mb-4 ${textclass}` : "banner-description mb-4"}>
                        <p className={textclass ? textclass : ""}>{bannerDescription}</p>
                    </div>
                    <div className="banner-cta">
                        <button className="cta-btn" type="submit" onClick={FunRedirect}>{btnTitle}</button>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Banner;