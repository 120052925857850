import React from 'react';
import Banner from '../Banner';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
// import ContactImg from "../../assets/img/aboutUs.jpg";

const ContactUs = ({ onSubmit, setName, name, setEmail, email, setSubject, subject, setMessage, message }) => {
    return (
        <section className='contactUs' id='ContactUs'>
            <Banner
                brdCurrent="Contact Us"
                brdLink="Home"
                brdPath="/"
                bannerTitle="Contact Us"
                bannerDescription="Reach Out to Us Have questions or inquiries? We're here to help! Contact us today, and our team will assist you."
                // btnTitle="Make A Appointment"
                textclass='text-white'
            />
            <Container>
                {/* <div className='row top-section pb-5'>
                    <div className='col-12 col-lg-6 description-block'>
                        <div className="section-heading">
                            <h3 className="section-title">FIND YOUR CONTACT PERSONS</h3>
                            <svg xmlns="http://www.w3.org/2000/svg" width="350" height="2" viewBox="0 0 350 2" fill="none">
                                <path d="M1 1L349 1.00003" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        </div>
                        <div className="section-description mt-3">
                            <p className="section-paragraph d-flex align-items-end">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras arcu urna, feugiat eu odio gravida, molestie sodales nisi. Donec feugiat metus vel sapien rutrum, tempus fringilla tortor laoreet. Pellentesque habitant morbi tristique senectus et netus.</p>
                        </div>
                        <div>
                            <button className='cta-btn'>Contact Us</button>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 mt-3 mt-lg-0'>
                        <img className='contact-img img-fluid' src={ContactImg} alt='contact us'/>
                    </div>
                </div> */}
                <div className='enquiry-section'>
                    <div className="section-heading">
                        <h3 className="section-title">Enquiry:</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="112" height="2" viewBox="0 0 112 2" fill="none">
                            <path d="M1 1L349 1.00003" stroke="#BE372C" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </div>
                    <div className="section-description mt-3">
                        <p className="section-paragraph d-flex align-items-end">Here you can send us an Enquiry concerning general questions about UIV Group</p>
                    </div>
                    <div className='contact-form'>
                        <form onSubmit={onSubmit}>
                            <div className='row'>
                                <div className='col-12 col-sm-6 col-md-4 form-group'>
                                    <input className='form-control' name="Name" id='Name' placeholder='name *' required onChange={e => setName(e.target.value)} value={name} />
                                </div>
                                <div className='col-12 col-sm-6 col-md-4 form-group'>
                                    <input className='form-control' name="Email" id='Email' placeholder='email *' required onChange={e => setEmail(e.target.value)} value={email} />
                                </div>
                                <div className='col-12 col-md-4 form-group'>
                                    <input className='form-control' name="Subject" id='Subject' placeholder='subject *' required onChange={e => setSubject(e.target.value)} value={subject} />
                                </div>
                                <div className='col-12 form-group'>
                                    <textarea className='form-control' name="Message" id='Message' placeholder='message' rows={5} onChange={e => setMessage(e.target.value)} value={message}></textarea>                                </div>
                            </div>
                            <div className='mt-4 d-flex justify-content-center'>
                                <button className='cta-btn d-block' type='submit'>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Container>
        </section>
    );
};

ContactUs.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    setName: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    setEmail: PropTypes.func.isRequired,
    subject: PropTypes.string.isRequired,
    setSubject: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    setMessage: PropTypes.func.isRequired,
};

export default ContactUs;