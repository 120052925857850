import React from 'react';
import Banner from '../components/Banner';
import PageHead from '../components/ManagementBoard/PageHead';
// import ManagementBoardBody from '../components/ManagementBoard/ManagementBoardBody';

const ManagementBoardPage = () => {
    return (
        <section id="ManagementBoardPage">
            <Banner
                brdCurrent="Management Board"
                brdLink="Home"
                brdPath="/"
                bannerTitle="MANAGEMENT BOARD"
                bannerDescription=""
                btnTitle="Make A Appointment"
            />
            <PageHead />
            {/* <ManagementBoardBody /> */}
        </section>
    );
};

export default ManagementBoardPage;