import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Corousal from "../components/Home/Corousal";
import AboutUs from "../components/Home/AboutUs";
import OurServices from "../components/Home/OurServices";
import Clients from "../components/Home/Clients";
import IndustryExpertise from "../components/Home/IndustryExpertise";
import Awards from "../components/Home/Awards";
import OurClients from "../components/Home/OurClients";

const Home = () => {
    const { pathname } = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    
    return (
        <>
            <Corousal />
            <AboutUs />
            <OurServices />
            <Clients />
            <IndustryExpertise />
            <Awards />
            <OurClients />
        </>
    );
};

export default Home;