import React from 'react';
import { Container } from 'react-bootstrap';

const AboutUsCareer = () => {
    return (
        <section className='text-center AboutUsCareer mb-5'>
            <Container>
                <div className='section-heading'>
                    <h3 className='section-tittle'>CAREER</h3>
                    <svg xmlns="http://www.w3.org/2000/svg" width="110" height="2" viewBox="0 0 110 2" fill="none">
                        <path d="M1 1L109 1.00001" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </div>
                <div className='section-description'>
                    <p>Looking for an exciting career opportunity? Explore our current openings and become a part of our dynamic team</p>
                </div>
                <div>
                   <a href='mailto:hrm@suiv.co.in  info@suiv.com' target="_blank" rel="noreferrer">
                    <button className='cta-btn'>Join Us Now</button>
                   </a>
                </div>
            </Container>
        </section>
    );
};

export default AboutUsCareer;