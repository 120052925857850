import React from "react";
import { Container } from "react-bootstrap";

const Clients = () => {
    return (
        <section className="text-center Clients">
            <Container>
                <div className="client-key-value-box d-flex justify-content-sm-between justify-eontent-center align-items-center flex-wrap">
                    <div className="">
                        <div className="purecounter client-value">11</div>
                        <div className="client-key">Years of Excellence</div>
                    </div>
                    <div className="">
                        <div className="client-value">2000+</div>
                        <div className="client-key">Employees</div>
                    </div>
                    <div className="">
                        <div className="client-value">100+</div>
                        <div className="client-key">Completed Projects</div>
                    </div>
                    <div className="">
                        <div className="client-value">20+</div>
                        <div className="client-key">Ongoing Projects</div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default Clients;