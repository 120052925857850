import React from "react";
import {Container } from "react-bootstrap";
import Banner from "../Banner";
// import Img1 from "../../assets/img/our_serveices_1.png";
// import Img2 from "../../assets/img/our_serveices_2.png";

const OurServices = () => {
    return (
        <section id="OS-OurServices">
            <Banner
                brdCurrent="Our Services"
                brdLink="Home"
                brdPath="/"
                bannerTitle="Our Services"
                bannerDescription=""
                btnTitle="Make A Appointment"
            />
            <Container>
                {/* <div className="row"> */}
                    <div className="section-heading">
                        <h3 className="section-title">About Our Service&apos;s:</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="350" height="2" viewBox="0 0 350 2" fill="none">
                            <path d="M1 1L349 1.00003" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div className="section-description mt-3">
                        {/* <p className="section-paragraph d-flex align-items-end">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras arcu urna, feugiat eu odio gravida, molestie sodales nisi. Donec feugiat metus vel sapien rutrum, tempus fringilla tortor laoreet. Pellentesque habitant morbi tristique senectus et netus.</p> */}
                    </div>
                {/* </div> */}
                <div className="row mt-4 mb-5">
                    <div className="col-12 col-sm-6">
                        <div className="big-img">
                            <div className="img-title">Electrical Maintenance</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">Electrical Maintenance</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <ul className="desc-para-list">
                                        <li className="desc-list-item">Annual Maintenance services</li>
                                        <li className="desc-list-item">Switch Yard Maintenance</li>
                                        <li className="desc-list-item">HT-LT line maintenance</li>
                                        <li className="desc-list-item">Lighting protection system</li>
                                        <li className="desc-list-item">Town Ship Maintenance</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="small-img">
                        <div className="img-title">C&I Maintenance</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">C&I Maintenance</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <ul className="desc-para-list">
                                        <li className="desc-list-item">AMC for C&I system Main Plant, CHP & Off-Site Area</li>
                                        <li className="desc-list-item">AMC For CCTV, PA, Battery & Fire system</li>
                                        <li className="desc-list-item">AMC For PLC, SWAS, SADC</li>
                                        <li className="desc-list-item">Testing and Calibrations Services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="small-img">
                        <div className="img-title">AHP / CHP & MGR Works</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">AHP / CHP & MGR Works</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <ul className="desc-para-list">
                                        <li className="desc-list-item">O&M of AHP / CHP, MGR, Wagon Tippler & Stacker -Reclaimer</li>
                                        <li className="desc-list-item">Operation and Maintenance of Diesel Loco</li>
                                        <li className="desc-list-item">Signal & Telecom Maintenance</li>
                                        <li className="desc-list-item">P-Way Track Maintenance Work </li>
                                        <li className="desc-list-item">Heavy Vehicle Maintenance Work</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="big-img">
                        <div className="img-title">EPC &#8212; Engineering, Procurements & Construction</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">EPC &#8212; Engineering, Procurements & Construction</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <ul className="desc-para-list">
                                        <li className="desc-list-item">Engineering</li>
                                        <li className="desc-list-item">Procurements</li>
                                        <li className="desc-list-item">Constructions</li>
                                        <li className="desc-list-item">Projects Managements </li>
                                        <li className="desc-list-item">Commissioning & Start-Up Services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="big-img">
                        <div className="img-title">O&M Operations and Maintenance Services </div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">O&M Operations and Maintenance Services </h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <ul className="desc-para-list">
                                        <li className="desc-list-item">AMC of Power Plants & Process Industries </li>
                                        <li className="desc-list-item">Preventive and break down Maintenance</li>
                                        <li className="desc-list-item">Overhauling & Modifications </li>
                                        <li className="desc-list-item">Contingency Services </li>
                                        <li className="desc-list-item">Operations Supports</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="small-img">
                        <div className="img-title">Railroad Track Care </div> */}
                            {/* <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">Railroad Track Care </h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                            </div> */}
                        {/* </div> */}
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="big-img">
                        <div className="img-title">MSS Man Power Supply & Services </div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">MSS Man Power Supply & Services </h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <ul className="desc-para-list">
                                        <li className="desc-list-item">Engineers, High Skills, Semi-Skilled, Un-Skilled manpower supply services </li>
                                        <li className="desc-list-item">Contracts for FMS, Fire safety & Material Managements</li>
                                        <li className="desc-list-item">Supply of Railway Retiree </li>
                                        <li className="desc-list-item">Industrial House Keeping services </li>
                                        {/* <li className="desc-list-item">Operations Supports</li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="big-img">
                        <div className="img-title">Heavy Vehicle Care </div> */}
                            {/* <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">Heavy Vehicle Care</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                            </div> */}
                        {/* </div> */}
                    </div>
                    <div className="col-12 col-sm-6">
                        {/* <div className="small-img">
                        <div className="img-title">Operational Support</div> */}
                            {/* <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">Operational Support</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                            </div> */}
                        {/* </div> */}
                    </div>
                    <div className="col-12 col-sm-6">
                        {/* <div className="small-img">
                        <div className="img-title">Railway Retiree Recruitment</div> */}
                            {/* <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">Railway Retiree Recruitment</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                            </div> */}
                        {/* </div> */}
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default OurServices;