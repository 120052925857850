import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Img1 from "../../assets/img/our_serveices_1.png";
// import Img2 from "../../assets/img/our_serveices_2.png";

const AboutUsOurServices = () => {
    return (
        <section className="AboutUsOurServices">
            <Container>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <h3 className="section-title">Our Services:</h3>
                        <svg xmlns="http://www.w3.org/2000/svg" width="136" height="2" viewBox="0 0 136 2" fill="none">
                            <path d="M1 1H135" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                    {/* <p className="col-12 col-sm-6 section-paragraph d-flex align-items-end">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras arcu urna, feugiat eu odio gravida, molestie sodales nisi. Donec feugiat metus vel sapien rutrum, tempus fringilla tortor laoreet. Pellentesque habitant morbi tristique senectus et netus.</p> */}
                </div>
                <div className="row mt-5">
                    <div className="col-12 col-sm-6">
                        <div className="big-img">
                            <div className="img-title">Electrical Maintenance</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">Electrical Maintenance</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <ul className="desc-para-list">
                                        <li className="desc-list-item">Annual Maintenance services</li>
                                        <li className="desc-list-item">Switch Yard Maintenance</li>
                                        <li className="desc-list-item">HT-LT line maintenance</li>
                                        <li className="desc-list-item">Lighting protection system</li>
                                        <li className="desc-list-item">Town Ship Maintenance</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="small-img">
                            <div className="img-title">C&I Maintenance</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">C&I Maintenance</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <ul className="desc-para-list">
                                        <li className="desc-list-item">AMC for C&I system Main Plant, CHP & Off-Site Area</li>
                                        <li className="desc-list-item">AMC For CCTV, PA, Battery & Fire system</li>
                                        <li className="desc-list-item">AMC For PLC, SWAS, SADC</li>
                                        <li className="desc-list-item">Testing and Calibrations Services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="small-img">
                            <div className="img-title">AHP / CHP & MGR Works</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">AHP / CHP & MGR Works</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <ul className="desc-para-list">
                                        <li className="desc-list-item">O&M of AHP / CHP, MGR, Wagon Tippler & Stacker -Reclaimer</li>
                                        <li className="desc-list-item">Operation and Maintenance of Diesel Loco</li>
                                        <li className="desc-list-item">Signal & Telecom Maintenance</li>
                                        <li className="desc-list-item">P-Way Track Maintenance Work </li>
                                        <li className="desc-list-item">Heavy Vehicle Maintenance Work</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="big-img">
                            <div className="img-title">EPC &#8212; Engineering, Procurements & Construction</div>
                            <div className="img-description">
                                <div className="desc-heading">
                                    <h3 className="desc-title">EPC &#8212; Engineering, Procurements & Construction</h3>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="2" viewBox="0 0 77 2" fill="none">
                                        <path d="M1 1L76 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="desc-paragraph">
                                    <ul className="desc-para-list">
                                        <li className="desc-list-item">Engineering</li>
                                        <li className="desc-list-item">Procurements</li>
                                        <li className="desc-list-item">Constructions</li>
                                        <li className="desc-list-item">Projects Managements </li>
                                        <li className="desc-list-item">Commissioning & Start-Up Services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-5 text-center">
                    <Link to="/Services"><button className="cta-btn" type="button">See More</button></Link>
                </div>
            </Container>
        </section>
    );
};

export default AboutUsOurServices;