// MyContextProvider.js
import React, { useEffect, useState } from 'react';
import ActiveLinkContext from './ActiveLinkContextBase';
import { useLocation } from "react-router-dom";

// eslint-disable-next-line react/prop-types
const ActiveLinkContextProvider = ({ children }) => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location?.pathname);

    useEffect(() => {
        setActiveLink(location?.pathname);
    }, [location]);

    return (
        <ActiveLinkContext.Provider value={{ activeLink, setActiveLink }}>
            {children}
        </ActiveLinkContext.Provider>
    );
    
};

export default ActiveLinkContextProvider;
