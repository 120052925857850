import React from "react";
import { Container } from "react-bootstrap";

const Clients = () => {
    return (
        <section className="text-center" id="Clients">
            <Container>
                {/* <h3 className="section-title">Embark on a Journey of Limitless Dreams</h3>
                <svg xmlns="http://www.w3.org/2000/svg" width="387" height="2" viewBox="0 0 387 2" fill="none">
                    <path d="M1 1L386 1.00003" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                </svg>
                <p className="section-paragraph my-4">In a world brimming with boundless possibilities and guided by the compass of dreams, we&apos;re here to Mold your aspirations into tangible reality. Together, we compose a symphony where targets materialize, resonating with the harmony of fulfilment.</p>
                <div className="mb-4">
                    <button className="make-appoint-btn">Make an Appointment</button>
                </div> */}
                <div className="client-key-value-box d-flex justify-content-sm-between justify-content-center align-items-center flex-wrap">
                    <div className="">
                        <div className="purecounter client-value">11</div>
                        <div className="client-key">Years of Excellence</div>
                    </div>
                    <div className="">
                        <div className="client-value">2000+</div>
                        <div className="client-key">Employees</div>
                    </div>
                    <div className="">
                        <div className="client-value">100+</div>
                        <div className="client-key">Completed Projects</div>
                    </div>
                    <div className="">
                        <div className="client-value">20+</div>
                        <div className="client-key">Ongoing Projects</div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default Clients;