import React from "react";
import "./App.css";
import "./assets/css/Responsive.css";
import "bootstrap/dist/css/bootstrap.css";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Home from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ActiveLinkContextProvider from "./Context/ActiveLinkContext";
import OurServicesPage from "./pages/OurServicesPage";
import Events from "./components/Event/Events";
import ContactUsPage from "./pages/ContactUsPage";
import AboutUsPage from "./pages/AboutUsPage";
import ManagementBoardPage from "./pages/ManagementBoardPage";

function App() {
  return (
    <BrowserRouter>
      <ActiveLinkContextProvider>
        <Header />

        <Routes>
          <Route index element={<Home />} />
          <Route exact path="/" element={<Home />} />
          <Route path="/AboutUs" element={<AboutUsPage />} />
          <Route path="/Services" element={<OurServicesPage />} />
          <Route path="/Assets" element={<Home />} />
          <Route path="/ManagementBoard" element={<ManagementBoardPage />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/ContactUs" element={<ContactUsPage />} />
          <Route path="/Career" element={<Home />} />
        </Routes>
        
        <Footer />
      </ActiveLinkContextProvider>
    </BrowserRouter>
  );
}

export default App;
