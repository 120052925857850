import React from "react";
import { Carousel } from "react-bootstrap";
import CorouselImg2 from "../../assets/img/Corousel/corousel2.jpg";
import CorouselImg1 from "../../assets/img/Corousel/corousel1.jpg";
import CorouselImg3 from "../../assets/img/Corousel/corousel3.jpg";

const Corousal = () => {
    return (
        <section id="Corousel">
            <Carousel>
                <Carousel.Item interval={3000}>
                    {/* <ExampleCarouselImage text="First slide" /> */}
                    <img className="carousel-image"  src={CorouselImg1} alt="" />
                    <Carousel.Caption>
                        <div>
                            <h2>Embark on a Journey of Limitless Dreams</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" width="256" height="2" viewBox="0 0 256 2" fill="none">
                                <path d="M1 1H255" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        </div>
                        <p className="text-justify">In a world brimming with boundless possibilities and guided by the compass of dreams, we&apos;re here to Mold your aspirations into tangible reality. Together, we compose a symphony where targets materialize, resonating with the harmony of fulfilment.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    {/* <ExampleCarouselImage text="Second slide" /> */}
                    <img className="carousel-image" src={CorouselImg2} alt="" />
                    <Carousel.Caption>
                        <div>
                            <h2>Dream, Believe, Achieve &#8212; Thriving Together</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" width="256" height="2" viewBox="0 0 256 2" fill="none">
                                <path d="M1 1H255" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        </div>
                        <p className="text-justify">May your endeavours be ignited by inspiration, propelling you to remarkable heights. Welcome to a world where targes know no limits, and possibilities are crafted into existence.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    {/* <ExampleCarouselImage text="Third slide" /> */}
                    <img className="carousel-image" src={CorouselImg3} alt="" />
                    <Carousel.Caption>
                        <div>
                            <h2>Elevate Your Vision &#8212; Your Success, Our Passion</h2>
                            <svg xmlns="http://www.w3.org/2000/svg" width="256" height="2" viewBox="0 0 256 2" fill="none">
                                <path d="M1 1H255" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        </div>
                        <p className="text-justify">Step into a realm where your vision is our mission. Together, let&apos;s sculpt success stories that defy expectations. Welcome to a place where aspirations become achievements.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </section>
    );
};

export default Corousal;