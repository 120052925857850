import React from 'react';
import { Container } from 'react-bootstrap';

const VisionMission = () => {
    return (
        <section className='visionMission'>
            <Container>
                <div className='row'>
                    <div className='col-12 col-md-6 d-flex align-items-stretch'>
                        <div className='visionBox'>
                            <div className='vision-heading'>
                                <h3 className='vision-title'>Our Vision</h3>
                                <svg xmlns="http://www.w3.org/2000/svg" width="62" height="2" viewBox="0 0 62 2" fill="none">
                                    <path d="M1 1L61 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                            <div className='vision-desription'>
                                <p>To provide best in class services to a global customer base through excellence in expertise of each individual employee and customer satisfaction</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 d-flex align-items-stretch'>
                        <div className='missionBox'>
                            <div className='mission-heading'>
                                <h3 className='mission-title'>Our Mission</h3>
                                <svg xmlns="http://www.w3.org/2000/svg" width="62" height="2" viewBox="0 0 62 2" fill="none">
                                    <path d="M1 1L61 1.00001" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                            <div className='mission-desription'>
                                <p>To be a globally recognized company offering innovative and pioneering services in ever growing power sector in India & Abroad</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default VisionMission;