import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Banner from '../components/Banner';
import PageHead from '../components/AboutUs/PageHead';
import Suivpl from '../components/AboutUs/Suivpl';
import Clients from '../components/AboutUs/Clients';
import AboutUsOurServices from '../components/AboutUs/AboutUsOurServices';
import AboutUsIndustryExpertise from '../components/AboutUs/AboutUsIndustryExpertise';
import AboutUsCareer from '../components/AboutUs/AboutUsCareer';
import VisionMission from '../components/AboutUs/VisionMission';

const AboutUsPage = () => {
    const { pathname } = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    
    return (
        <section className='AboutUsPage' id="AboutUsPage">
            <Banner
                brdCurrent="About Us"
                brdLink="Home"
                brdPath="/"
                bannerTitle="ABOUT US"
                bannerDescription=""
                btnTitle="Make A Appointment"
            />
            <PageHead />
            <Suivpl />
            <Clients />
            <AboutUsOurServices />
            <VisionMission />
            <AboutUsIndustryExpertise />
            <AboutUsCareer />
        </section>
    );
};

export default AboutUsPage;