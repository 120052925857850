import React, { useEffect } from 'react';
import OurServices from '../components/OurServices/OurServices';
import { useLocation } from 'react-router-dom';

const OurServicesPage = () => {
    const { pathname } = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <OurServices />
        </>
    );
};

export default OurServicesPage;