import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import Client1 from "../../assets/img/Clients/KPS.jpeg";
import Client2 from "../../assets/img/Clients/NSPCLLOGO.png";
import Client3 from "../../assets/img/Clients/NTPC LOGO.png";
import Client4 from "../../assets/img/Clients/RMC-logo-D2D21184ED-seeklogo.com_.jpeg";
import Client5 from "../../assets/img/Clients/ADANI LOGO.png";
import Client6 from "../../assets/img/Clients/UPL LOGO.jpeg";
import Client7 from "../../assets/img/Clients/gmr.png";
import Client8 from "../../assets/img/Clients/ksk-logo-1.png";
import Client9 from "../../assets/img/Clients/APCPL LOGO.png";
// import Client10 from "../../assets/img/Clients/HMEL LOGO (STOP).png";
import Client11 from "../../assets/img/Clients/LOGO HURL.webp";
import Client12 from "../../assets/img/Clients/RVUN LOGO.jpeg";
import Client13 from "../../assets/img/Clients/MAHAGENCO LOGO.png";
const OurClients = () => {
    return (
        <section id='OurClients'>
            <Container>
                <div className='section-title text-center'>
                    <h3>Our Clients:</h3>
                    <svg className='d-none d-sm-inline' xmlns="http://www.w3.org/2000/svg" width="344" height="2" viewBox="0 0 344 2" fill="none">
                        <path d="M1 1L343 1.00003" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                    </svg>

                    <svg className="d-inline d-sm-none" xmlns="http://www.w3.org/2000/svg" width="280" height="2" viewBox="0 0 344 2" fill="none">
                        <path d="M1 1L343 1.00003" stroke="#BE372C" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </div>
                <div className='d-flex justify-content-center w-100 my-3 text-center'>
                    {/* <p className='section-description'>We take pride in serving a wide spectrum of clients, ranging from Thermal and Hydro power generation plants to prestigious organizations like NSPCL, NTPC, SAIL (BSP), HURL, Adani, and GMR. Our unwavering commitment to integrity, safety, quality, and exceeding customer expectations drives our success.</p> */}
                </div>
                {/* slider */}
                <Carousel interval={2000}>
                    <Carousel.Item>
                        <div className='award-corousel-image'>
                            <div className='background-box'></div>
                            <img className="carousel-image" src={Client1} alt="" />
                        </div>
                        <Carousel.Caption>
                            <p className='mt-2'><span>&ldquo;</span>We take pride in serving a wide spectrum of clients, ranging from Thermal and Hydro power generation plants to prestigious organizations like NSPCL, NTPC, SAIL (BSP), HURL, Adani, and GMR. Our unwavering commitment to integrity, safety, quality, and exceeding customer expectations drives our success.<span>&rdquo;</span></p>
                            <div className='text-center'>
                                <h2>&#8212; Kepco Kps &#8212;</h2>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image'>
                            <div className='background-box'></div>
                            <img className="carousel-image" src={Client2} alt="" />
                        </div>
                        <Carousel.Caption>
                            <p className='mt-2'><span>&ldquo;</span>We take pride in serving a wide spectrum of clients, ranging from Thermal and Hydro power generation plants to prestigious organizations like NSPCL, NTPC, SAIL (BSP), HURL, Adani, and GMR. Our unwavering commitment to integrity, safety, quality, and exceeding customer expectations drives our success.<span>&rdquo;</span></p>
                            <div className='text-center'>
                                <h2>&#8212; NSPCL &#8212;</h2>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image'>
                            <div className='background-box'></div>
                            <img className="carousel-image" src={Client3} alt="" />
                        </div>
                        <Carousel.Caption>
                            <p className='mt-2'><span>&ldquo;</span>We take pride in serving a wide spectrum of clients, ranging from Thermal and Hydro power generation plants to prestigious organizations like NSPCL, NTPC, SAIL (BSP), HURL, Adani, and GMR. Our unwavering commitment to integrity, safety, quality, and exceeding customer expectations drives our success.<span>&rdquo;</span></p>
                            <div className='text-center'>
                                <h2>&#8212; NTPC &#8212;</h2>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image'>
                            <div className='background-box'></div>
                            <img className="carousel-image" src={Client4} alt="" />
                        </div>
                        <Carousel.Caption>
                            <p className='mt-2'><span>&ldquo;</span>We take pride in serving a wide spectrum of clients, ranging from Thermal and Hydro power generation plants to prestigious organizations like NSPCL, NTPC, SAIL (BSP), HURL, Adani, and GMR. Our unwavering commitment to integrity, safety, quality, and exceeding customer expectations drives our success.<span>&rdquo;</span></p>
                            <div className='text-center'>
                                <h2>&#8212; RMC &#8212;</h2>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image'>
                            <div className='background-box'></div>
                            <img className="carousel-image" src={Client5} alt="" />
                        </div>
                        <Carousel.Caption>
                            <p className='mt-2'><span>&ldquo;</span>We take pride in serving a wide spectrum of clients, ranging from Thermal and Hydro power generation plants to prestigious organizations like NSPCL, NTPC, SAIL (BSP), HURL, Adani, and GMR. Our unwavering commitment to integrity, safety, quality, and exceeding customer expectations drives our success.<span>&rdquo;</span></p>
                            <div className='text-center'>
                                <h2>&#8212; adani &#8212;</h2>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image'>
                            <div className='background-box'></div>
                            <img className="carousel-image" src={Client6} alt="" />
                        </div>
                        <Carousel.Caption>
                            <p className='mt-2'><span>&ldquo;</span>We take pride in serving a wide spectrum of clients, ranging from Thermal and Hydro power generation plants to prestigious organizations like NSPCL, NTPC, SAIL (BSP), HURL, Adani, and GMR. Our unwavering commitment to integrity, safety, quality, and exceeding customer expectations drives our success.<span>&rdquo;</span></p>
                            <div className='text-center'>
                                <h2>&#8212; UPL &#8212;</h2>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image'>
                            <div className='background-box'></div>
                            <img className="carousel-image" src={Client7} alt="" />
                        </div>
                        <Carousel.Caption>
                            <p className='mt-2'><span>&ldquo;</span>We take pride in serving a wide spectrum of clients, ranging from Thermal and Hydro power generation plants to prestigious organizations like NSPCL, NTPC, SAIL (BSP), HURL, Adani, and GMR. Our unwavering commitment to integrity, safety, quality, and exceeding customer expectations drives our success.<span>&rdquo;</span></p>
                            <div className='text-center'>
                                <h2>&#8212; GMR &#8212;</h2>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image'>
                            <div className='background-box'></div>
                            <img className="carousel-image" src={Client8} alt="" />
                        </div>
                        <Carousel.Caption>
                            <p className='mt-2'><span>&ldquo;</span>We take pride in serving a wide spectrum of clients, ranging from Thermal and Hydro power generation plants to prestigious organizations like NSPCL, NTPC, SAIL (BSP), HURL, Adani, and GMR. Our unwavering commitment to integrity, safety, quality, and exceeding customer expectations drives our success.<span>&rdquo;</span></p>
                            <div className='text-center'>
                                <h2>&#8212; KSK &#8212;</h2>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image'>
                            <div className='background-box'></div>
                            <img className="carousel-image" src={Client9} alt="" />
                        </div>
                        <Carousel.Caption>
                            <p className='mt-2'><span>&ldquo;</span>We take pride in serving a wide spectrum of clients, ranging from Thermal and Hydro power generation plants to prestigious organizations like NSPCL, NTPC, SAIL (BSP), HURL, Adani, and GMR. Our unwavering commitment to integrity, safety, quality, and exceeding customer expectations drives our success.<span>&rdquo;</span></p>
                            <div className='text-center'>
                                <h2>&#8212; APCPL &#8212;</h2>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    {/* <Carousel.Item>
                        <div className='award-corousel-image'>
                            <div className='background-box'></div>
                            <img className="carousel-image" src={Client10} alt="" />
                        </div>
                        <Carousel.Caption>
                            <p className='mt-2'><span>&ldquo;</span>We take pride in serving a wide spectrum of clients, ranging from Thermal and Hydro power generation plants to prestigious organizations like NSPCL, NTPC, SAIL (BSP), HURL, Adani, and GMR. Our unwavering commitment to integrity, safety, quality, and exceeding customer expectations drives our success.<span>&rdquo;</span></p>
                            <div className='text-center'>
                                <h2>&#8212; HMEL &#8212;</h2>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item> */}
                    <Carousel.Item>
                        <div className='award-corousel-image'>
                            <div className='background-box'></div>
                            <img className="carousel-image" src={Client11} alt="" />
                        </div>
                        <Carousel.Caption>
                            <p className='mt-2'><span>&ldquo;</span>We take pride in serving a wide spectrum of clients, ranging from Thermal and Hydro power generation plants to prestigious organizations like NSPCL, NTPC, SAIL (BSP), HURL, Adani, and GMR. Our unwavering commitment to integrity, safety, quality, and exceeding customer expectations drives our success.<span>&rdquo;</span></p>
                            <div className='text-center'>
                                <h2>&#8212; HURL &#8212;</h2>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image'>
                            <div className='background-box'></div>
                            <img className="carousel-image" src={Client12} alt="" />
                        </div>
                        <Carousel.Caption>
                            <p className='mt-2'><span>&ldquo;</span>We take pride in serving a wide spectrum of clients, ranging from Thermal and Hydro power generation plants to prestigious organizations like NSPCL, NTPC, SAIL (BSP), HURL, Adani, and GMR. Our unwavering commitment to integrity, safety, quality, and exceeding customer expectations drives our success.<span>&rdquo;</span></p>
                            <div className='text-center'>
                                <h2>&#8212; RVUN &#8212;</h2>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image'>
                            <div className='background-box'></div>
                            <img className="carousel-image" src={Client13} alt="" />
                        </div>
                        <Carousel.Caption>
                            <p className='mt-2'><span>&ldquo;</span>We take pride in serving a wide spectrum of clients, ranging from Thermal and Hydro power generation plants to prestigious organizations like NSPCL, NTPC, SAIL (BSP), HURL, Adani, and GMR. Our unwavering commitment to integrity, safety, quality, and exceeding customer expectations drives our success.<span>&rdquo;</span></p>
                            <div className='text-center'>
                                <h2>&#8212; MAHAGENCO &#8212;</h2>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </Container>
        </section>
    );
};

export default OurClients;