import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import Award1 from "../../assets/img/Awards/award 1.jpeg";
import Award2 from "../../assets/img/Awards/award 2.jpeg";
import Award3 from "../../assets/img/Awards/AWARDS (1).jpg";
import Award4 from "../../assets/img/Awards/AWARDS (2).jpg";
import Award5 from "../../assets/img/Awards/AWARDS (3).jpg";
import Award7 from "../../assets/img/Awards/ADANI 2.jpg";
import Award8 from "../../assets/img/Awards/ADANI.jpg";
import Award9 from "../../assets/img/Awards/GMR.jpg";
import Award10 from "../../assets/img/Awards/NTPC (1).jpg";
import Award11 from "../../assets/img/Awards/NTPC (2).jpg";
import Award13 from "../../assets/img/Awards/NTPC (5).jpg";
const Awards = () => {
    return (
        <section className='' id='Awards'>
            <Container>
                <div className='section-title text-center'>
                    <h3>Awards and Recognitions:</h3>
                    <svg xmlns="http://www.w3.org/2000/svg" width="106" height="2" viewBox="0 0 106 2" fill="none">
                        <path d="M1 1L105 1.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </div>
                <div className='d-flex justify-content-center w-100 my-3 text-center'>
                    <p className='section-description'>At Various clients, SAI URJA INDO VENTURES PVT LTD relentless dedication to hard work and a systematic work culture has been consistently recognized and celebrated. These awards stand as a testament to our unwavering commitment to excellence, innovation, and delivering unparalleled solutions to our clients. We take pride in our achievements and continue to raise the bar in every endeavours.</p>
                </div>
                {/* slider */}
                <Carousel interval={20000}>
                    <Carousel.Item   >
                        {/* <ExampleCarouselImage text="First slide" /> */}
                        <div className='award-corousel-image  '>
                            <div className='background-box'></div>
                            <img className="carousel-image img-fluid" src={Award1} alt="" />
                        </div>
                        <Carousel.Caption >
                            <div>
                                <h2>NTPC</h2>
                                <svg xmlns="http://www.w3.org/2000/svg" width="159" height="2" viewBox="0 0 159 2" fill="none">
                                    <path d="M1 1H158" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                            <p className='mt-2'>Honouring Excellence: Proud winners of awards from NTPC. These accolades reflect our relentless dedication to excellence, safety, and quality in all our endeavours. They serve as a testament to our commitment to delivering outstanding solutions and our unwavering pursuit of success.</p>
                            {/* <ul>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                            </ul> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image '>
                            <div className='background-box  '></div>
                            <img className="carousel-image img-fluid" src={Award2} alt="" />
                        </div>
                        <Carousel.Caption >
                            <div>
                                <h2>NTPC</h2>
                                <svg xmlns="http://www.w3.org/2000/svg" width="159" height="2" viewBox="0 0 159 2" fill="none">
                                    <path d="M1 1H158" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                            <p className='mt-2'>Honouring Excellence: Proud winners of awards from NTPC. These accolades reflect our relentless dedication to excellence, safety, and quality in all our endeavours. They serve as a testament to our commitment to delivering outstanding solutions and our unwavering pursuit of success.</p>
                            {/* <ul>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                            </ul> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image '>
                            <div className='background-box '></div>
                            <img className="carousel-image img-fluid" src={Award3} alt="" />
                        </div>
                        <Carousel.Caption >
                            <div>
                                <h2>NTPC</h2>
                                <svg xmlns="http://www.w3.org/2000/svg" width="159" height="2" viewBox="0 0 159 2" fill="none">
                                    <path d="M1 1H158" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                            <p className='mt-2'>Honouring Excellence: Proud winners of awards from NTPC. These accolades reflect our relentless dedication to excellence, safety, and quality in all our endeavours. They serve as a testament to our commitment to delivering outstanding solutions and our unwavering pursuit of success</p>
                            {/* <ul>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                            </ul> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    
                    <Carousel.Item>
                        <div className='award-corousel-image '>
                            <div className='background-box  '></div>
                            <img className="carousel-image img-fluid" src={Award4} alt="" />
                        </div>
                        <Carousel.Caption >
                            <div>
                                <h2>NTPC</h2>
                                <svg xmlns="http://www.w3.org/2000/svg" width="159" height="2" viewBox="0 0 159 2" fill="none">
                                    <path d="M1 1H158" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                            <p className='mt-2'>Honouring Excellence: Proud winners of awards from NTPC. These accolades reflect our relentless dedication to excellence, safety, and quality in all our endeavours. They serve as a testament to our commitment to delivering outstanding solutions and our unwavering pursuit of success</p>
                            {/* <ul>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                            </ul> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    
                    <Carousel.Item>
                        <div className='award-corousel-image '>
                            <div className='background-box  '></div>
                            <img className="carousel-image img-fluid" src={Award5} alt="" />
                        </div>
                        <Carousel.Caption>
                            <div>
                                <h2>NTPC</h2>
                                <svg xmlns="http://www.w3.org/2000/svg" width="159" height="2" viewBox="0 0 159 2" fill="none">
                                    <path d="M1 1H158" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                            <p className='mt-2'>Honouring Excellence: Proud winners of awards from NTPC. These accolades reflect our relentless dedication to excellence, safety, and quality in all our endeavours. They serve as a testament to our commitment to delivering outstanding solutions and our unwavering pursuit of success</p>
                            {/* <ul>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                            </ul> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image '>
                            <div className='background-box  '></div>
                            <img className="carousel-image img-fluid" src={Award7} alt="" />
                        </div>
                        <Carousel.Caption >
                            <div>
                                <h2>ADANI AWARDS</h2>
                                <svg xmlns="http://www.w3.org/2000/svg" width="159" height="2" viewBox="0 0 159 2" fill="none">
                                    <path d="M1 1H158" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                            <p className='mt-2'>We proudly announce our team&#39;s exceptional achievements as award winners from Adani. These accolades are a testament to our unwavering commitment to excellence, innovation, and outstanding service. The Adani Awards recognize our dedication to the highest standards of quality and our significant contributions to the industry. We are honoured to be recipients of these prestigious awards, which motivate us to continue striving for excellence.</p>
                            {/* <ul>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                            </ul> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image '>
                            <div className='background-box  '></div>
                            <img className="carousel-image img-fluid" src={Award8} alt="" />
                        </div>
                        <Carousel.Caption >
                            <div>
                                <h2>ADANI AWARDS</h2>
                                <svg xmlns="http://www.w3.org/2000/svg" width="159" height="2" viewBox="0 0 159 2" fill="none">
                                    <path d="M1 1H158" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                            <p className='mt-2'>We proudly announce our team&#39;s exceptional achievements as award winners from Adani. These accolades are a testament to our unwavering commitment to excellence, innovation, and outstanding service. The Adani Awards recognize our dedication to the highest standards of quality and our significant contributions to the industry. We are honoured to be recipients of these prestigious awards, which motivate us to continue striving for excellence.</p>
                            {/* <ul>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                            </ul> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image '>
                            <div className='background-box  '></div>
                            <img className="carousel-image img-fluid" src={Award9} alt="" />
                        </div>
                        <Carousel.Caption >
                            <div>
                                <h2>GMR</h2>
                                <svg xmlns="http://www.w3.org/2000/svg" width="159" height="2" viewBox="0 0 159 2" fill="none">
                                    <path d="M1 1H158" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                            <p className='mt-2'>Embracing Excellence: Celebrating our team&#39;s triumph as award winners at GMR. These accolades are a testament to our commitment to quality, innovation, and exceptional service. They inspire us to reach even greater heights in our journey of excellence and dedication</p>
                            {/* <ul>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                            </ul> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image '>
                            <div className='background-box '></div>
                            <img className="carousel-image img-fluid" src={Award10} alt="" />
                        </div>
                        <Carousel.Caption >
                            <div>
                                <h2>National Thermal Power Corporation</h2>
                                <svg xmlns="http://www.w3.org/2000/svg" width="159" height="2" viewBox="0 0 159 2" fill="none">
                                    <path d="M1 1H158" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                            <p className='mt-2'>We take pride in serving a wide spectrum of clients, ranging from Thermal and Hydro power generation plants to prestigious organizations like NSPCL, NTPC, SAIL (BSP), HURL, Adani, and GMR. Our unwavering commitment to integrity, safety, quality, and exceeding customer expectations drives our success</p>
                            {/* <ul>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                            </ul> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image'>
                            <div className='background-box '></div>
                            <img className="carousel-image img-fluid" src={Award11} alt="" />
                        </div>
                        <Carousel.Caption >
                            <div>
                                <h2>National Thermal Power Corporation</h2>
                                <svg xmlns="http://www.w3.org/2000/svg" width="159" height="2" viewBox="0 0 159 2" fill="none">
                                    <path d="M1 1H158" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                            <p className='mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras arcu urna, feugiat eu odio gravida, molestie sodales nisi. Donec feugiat metus vel sapien rutrum, tempus fringilla tortor laoreet. Pellentesque habitant morbi tristique senectus et netus.</p>
                            {/* <ul>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                            </ul> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='award-corousel-image '>
                            <div className='background-box  '></div>
                            <img className="carousel-image img-fluid" src={Award13} alt="" />
                        </div>
                        <Carousel.Caption>
                            <div>
                                <h2>National Thermal Power Corporation</h2>
                                <svg xmlns="http://www.w3.org/2000/svg" width="159" height="2" viewBox="0 0 159 2" fill="none">
                                    <path d="M1 1H158" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                </svg>
                            </div>
                            <p className='mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras arcu urna, feugiat eu odio gravida, molestie sodales nisi. Donec feugiat metus vel sapien rutrum, tempus fringilla tortor laoreet. Pellentesque habitant morbi tristique senectus et netus.</p>
                            {/* <ul>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                                <li><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit</span></li>
                            </ul> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </Container>
        </section>
    );
};

export default Awards;