import React from "react";
import Logo from "../assets/img/logo.png";
import ScrollToTop from "../components/Footer/ScrollToTop";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer id="footer">
            <ScrollToTop />
            <div className="footer-top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-6  col-xl-4 p-3">
                            <a href="#header" className="scrollto footer-logo">
                                <img src={Logo} alt="" />
                            </a>
                            <p>SUIVPL is one of the most trusted agency in O & M of thermal power plants with speciality in Electrical, C&I, Mechanical, AHP/CHP Operation & Maintenance. We are evolving into a prominent supporter of power, oil & gas, paper, sugar, chemical, solar, infrastructure, FMS of Govt, private & commercial sectors. Our diverse services include EPC, project management, operation & maintenance in various sectors.</p>
                        </div>
                        <div className="col-12 col-md-6 col-xl-2">
                            <h5>Quick Links:</h5>
                            <ul>
                                <li><Link to="/" className="quick-links" href="#">Home</Link></li>
                                <li><Link to="/ManagementBoard" className="quick-links" href="#">Management Board</Link></li>
                                <li><Link to="/Events" className="quick-links" href="#">Event&apos;s</Link></li>
                                <li><Link to="/ContactUs" className="quick-links" href="#">Contact Us</Link></li>
                                <li><Link to="/AboutUs" className="quick-links" href="#">About Us</Link></li>
                                {/* <li><Link to="/" className="quick-links" href="#">Career</Link></li> */}
                                {/* <li><Link to="/" className="quick-links" href="#">FAQ&apos;s</Link></li> */}
                                {/* <li><Link to="/" className="quick-links" href="#">Blog&apos;s</Link></li> */}
                            </ul>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                            <h5>Our Services:</h5>
                            <ul>
                                <li><Link to="/Services" className="quick-links" href="#">Electrical Maintenance</Link></li>
                                <li><Link to="/Services" className="quick-links" href="#">EPC–Engineering, Procurements & Construction</Link></li>
                                <li><Link to="/Services" className="quick-links" href="#">O&M Operations and Maintenance Services</Link></li>
                                <li><Link to="/Services" className="quick-links" href="#">Control and Instrumentations</Link></li>
                                <li><Link to="/Services" className="quick-links" href="#">MSS Man Power Supply & Services</Link></li>
                                <li><Link to="/Services" className="quick-links" href="#">AHP/CHP & MGR Works</Link></li>
                                {/* <li><Link to="/Services" className="quick-links" href="#">Operational Support</Link></li>
                                <li><Link to="/Services" className="quick-links" href="#">View All</Link></li> */}
                            </ul>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                            <h5>Contact Us:</h5>
                            <h6>Address:</h6>
                            <p className="contact-us">G-15, Jayanti Nagari IV, Besa Road, Manish Nagar, Nagpur - 440037, Maharashtra</p>
                            <h6>Call Us:</h6>
                            <p className="contact-us">+919970778169</p>
                            <h6>Email:</h6>
                            <p className="contact-us">info@suiv.com / hrm@suiv.co.in</p>
                            <div className="social-links">
                                <a href="#" className="twitter">
                                    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8579 3.0625C10.8579 3.17969 10.8579 3.27344 10.8579 3.39062C10.8579 6.64844 8.39697 10.375 3.87354 10.375C2.46729 10.375 1.17822 9.97656 0.100098 9.27344C0.287598 9.29688 0.475098 9.32031 0.686035 9.32031C1.83447 9.32031 2.88916 8.92188 3.73291 8.26562C2.65479 8.24219 1.74072 7.53906 1.43604 6.55469C1.6001 6.57812 1.74072 6.60156 1.90479 6.60156C2.11572 6.60156 2.3501 6.55469 2.5376 6.50781C1.4126 6.27344 0.568848 5.28906 0.568848 4.09375V4.07031C0.896973 4.25781 1.29541 4.35156 1.69385 4.375C1.01416 3.92969 0.592285 3.17969 0.592285 2.33594C0.592285 1.86719 0.709473 1.44531 0.92041 1.09375C2.13916 2.57031 3.96729 3.55469 6.00635 3.67188C5.95947 3.48438 5.93604 3.29688 5.93604 3.10938C5.93604 1.75 7.0376 0.648438 8.39697 0.648438C9.1001 0.648438 9.73291 0.929688 10.2017 1.42188C10.7407 1.30469 11.2798 1.09375 11.7485 0.8125C11.561 1.39844 11.186 1.86719 10.6704 2.17188C11.1626 2.125 11.6548 1.98438 12.0767 1.79688C11.7485 2.28906 11.3267 2.71094 10.8579 3.0625Z" fill="#464646"/>
                                    </svg>
                                </a>
                                <a  className="facebook" href="https://www.facebook.com/SUIVPL.12" target="_blank" rel="noreferrer">
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.2124 6.5C12.2124 9.40625 10.0796 11.8203 7.29053 12.2422V8.1875H8.6499L8.90771 6.5H7.29053V5.42188C7.29053 4.95312 7.5249 4.50781 8.25146 4.50781H8.97803V3.07812C8.97803 3.07812 8.32178 2.96094 7.66553 2.96094C6.35303 2.96094 5.48584 3.78125 5.48584 5.23438V6.5H4.00928V8.1875H5.48584V12.2422C2.69678 11.8203 0.587402 9.40625 0.587402 6.5C0.587402 3.28906 3.18896 0.6875 6.3999 0.6875C9.61084 0.6875 12.2124 3.28906 12.2124 6.5Z" fill="#464646"/>
                                    </svg>
                                </a>
                                <a href="#" className="instagram">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.69971 2.80469C7.17627 2.80469 8.39502 4.02344 8.39502 5.5C8.39502 7 7.17627 8.19531 5.69971 8.19531C4.19971 8.19531 3.00439 7 3.00439 5.5C3.00439 4.02344 4.19971 2.80469 5.69971 2.80469ZM5.69971 7.25781C6.66064 7.25781 7.43408 6.48438 7.43408 5.5C7.43408 4.53906 6.66064 3.76562 5.69971 3.76562C4.71533 3.76562 3.94189 4.53906 3.94189 5.5C3.94189 6.48438 4.73877 7.25781 5.69971 7.25781ZM9.12158 2.71094C9.12158 3.0625 8.84033 3.34375 8.48877 3.34375C8.13721 3.34375 7.85596 3.0625 7.85596 2.71094C7.85596 2.35938 8.13721 2.07812 8.48877 2.07812C8.84033 2.07812 9.12158 2.35938 9.12158 2.71094ZM10.9028 3.34375C10.9497 4.21094 10.9497 6.8125 10.9028 7.67969C10.856 8.52344 10.6685 9.25 10.0591 9.88281C9.44971 10.4922 8.69971 10.6797 7.85596 10.7266C6.98877 10.7734 4.38721 10.7734 3.52002 10.7266C2.67627 10.6797 1.94971 10.4922 1.31689 9.88281C0.70752 9.25 0.52002 8.52344 0.473145 7.67969C0.42627 6.8125 0.42627 4.21094 0.473145 3.34375C0.52002 2.5 0.70752 1.75 1.31689 1.14062C1.94971 0.53125 2.67627 0.34375 3.52002 0.296875C4.38721 0.25 6.98877 0.25 7.85596 0.296875C8.69971 0.34375 9.44971 0.53125 10.0591 1.14062C10.6685 1.75 10.856 2.5 10.9028 3.34375ZM9.77783 8.59375C10.0591 7.91406 9.98877 6.27344 9.98877 5.5C9.98877 4.75 10.0591 3.10938 9.77783 2.40625C9.59033 1.96094 9.23877 1.58594 8.79346 1.42188C8.09033 1.14062 6.44971 1.21094 5.69971 1.21094C4.92627 1.21094 3.28564 1.14062 2.60596 1.42188C2.13721 1.60938 1.78564 1.96094 1.59814 2.40625C1.31689 3.10938 1.38721 4.75 1.38721 5.5C1.38721 6.27344 1.31689 7.91406 1.59814 8.59375C1.78564 9.0625 2.13721 9.41406 2.60596 9.60156C3.28564 9.88281 4.92627 9.8125 5.69971 9.8125C6.44971 9.8125 8.09033 9.88281 8.79346 9.60156C9.23877 9.41406 9.61377 9.0625 9.77783 8.59375Z" fill="#464646"/>
                                    </svg>
                                </a>
                                <a href="#" className="linkedIn" target="_blank" rel="noreferrer">
                                    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.5005 0.25C10.8989 0.25 11.2505 0.601562 11.2505 1.02344V10C11.2505 10.4219 10.8989 10.75 10.5005 10.75H1.47705C1.07861 10.75 0.750488 10.4219 0.750488 10V1.02344C0.750488 0.601562 1.07861 0.25 1.47705 0.25H10.5005ZM3.91455 9.25V4.25781H2.36768V9.25H3.91455ZM3.14111 3.55469C3.6333 3.55469 4.03174 3.15625 4.03174 2.66406C4.03174 2.17188 3.6333 1.75 3.14111 1.75C2.62549 1.75 2.22705 2.17188 2.22705 2.66406C2.22705 3.15625 2.62549 3.55469 3.14111 3.55469ZM9.75049 9.25V6.50781C9.75049 5.17188 9.4458 4.11719 7.87549 4.11719C7.12549 4.11719 6.60986 4.53906 6.39893 4.9375H6.37549V4.25781H4.89893V9.25H6.4458V6.78906C6.4458 6.13281 6.56299 5.5 7.3833 5.5C8.18018 5.5 8.18018 6.25 8.18018 6.8125V9.25H9.75049Z" fill="#464646"/>
                                    </svg>
                                </a>
                                <a href="#" className="youtube">
                                    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.4175 1.42969C13.6987 2.41406 13.6987 4.52344 13.6987 4.52344C13.6987 4.52344 13.6987 6.60938 13.4175 7.61719C13.2769 8.17969 12.8315 8.60156 12.2925 8.74219C11.2847 9 7.30029 9 7.30029 9C7.30029 9 3.29248 9 2.28467 8.74219C1.74561 8.60156 1.30029 8.17969 1.15967 7.61719C0.878418 6.60938 0.878418 4.52344 0.878418 4.52344C0.878418 4.52344 0.878418 2.41406 1.15967 1.42969C1.30029 0.867188 1.74561 0.421875 2.28467 0.28125C3.29248 0 7.30029 0 7.30029 0C7.30029 0 11.2847 0 12.2925 0.28125C12.8315 0.421875 13.2769 0.867188 13.4175 1.42969ZM5.98779 6.42188L9.31592 4.52344L5.98779 2.625V6.42188Z" fill="#464646"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="footer-bottom clearfix">
                <div className="copyright">
                        SUIV Group &copy; { new Date().getFullYear()} All Rights Reserved
                </div>
            </div>
        </footer>
    );
};

export default Footer;